import { Container, Box, Typography, Grid, Button} from "@mui/material";
// import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {  useDispatch } from "react-redux";
import {  updateRedeemModalOpenState } from "../../../reducers/commonSlice";

import ResponsiveCarousel from "../../common/ResponsiveCarousel"

import {useMediaQuery} from "@material-ui/core";
import { useTheme } from '@mui/material/styles';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
export default function AboutPowerIcon(props) {
  const matchDownSm = useMediaQuery(useTheme().breakpoints.down('sm'));
  
const dispatch = useDispatch();
const { t } = useTranslation();
return (
    <Box sx={{ my:4}}>
      <ResponsiveCarousel 
            imageSet={["special_preview/01.gif","special_preview/02.gif","special_preview/03.gif","special_preview/04.gif","special_preview/05.gif","special_preview/06.gif","special_preview/07.gif","special_preview/08.gif","special_preview/09.gif","special_preview/10.gif",]} 
            props={{
              infiniteLoop:false,
              centerMode:true, 
              centerSlidePercentage:(matchDownSm)?50:32.5, 
              showArrows:true, 
              showIndicators:false
            }}/>
        <Container maxWidth="lg" >
        
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          p={3} pt={10}
        >
            
            
            <Grid container rowSpacing={0} >
                <Grid item xs={12} md={6} >
                    <Typography mb={4}>{t("home_special_edition_desc")}{t("opensea_btn_content_1")} <Box component={"a"} target="_blank" href="https://opensea.io/collection/narshk-powericon-special-edition">{t("opensea")}</Box>{t("opensea_btn_content_2")}  {">"} </Typography>
                </Grid>
                <Grid item xs={12} md={3}  align="center" >
                <Button
                      variant="outlined"
                      color="white"
                      sx={{ mb: 0.5, width: "13rem"}}
                      onClick={() => {
                        dispatch(updateRedeemModalOpenState(true))
                      }}
                    >
                      {t("home_hero_btn")}
                    </Button>
                </Grid>
                <Grid item xs={12} md={3} align="center" >
                <Button
                  variant="outlined"
                  color="white"
                  sx={{ mb: 0.5, width: "13rem"}}
                  onClick={() => {
                    window.location.href = "/mint";
                  }}
                >
                  {t("home_mint_btn")}
                </Button>
                </Grid>
            </Grid>
        </Grid>               
    
        </Container>
    </Box>
)
}

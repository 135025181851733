

import {Button, Box,  Typography ,Grid} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Component(props) {
    const { t } = useTranslation();
    const closeParentModal = props.closeModal || (() => { console.log("closeParentModal")} );
    const [redeemInfo] = useState(JSON.parse(localStorage.getItem("redeemInfo"))??{});
    
    const ConfirmData = () =>{
        
        return (<Box mb={3} sx={{borderTop:"1px solid white",borderBottom:"1px solid white"}}>
        <Grid container spacing={1} py={1}>
            <Grid item xs={5} sm={4} md={3}>{t("modal_step3_data_1")}</Grid>
            <Grid item xs={7} sm={8} md={7}>{redeemInfo?.name}</Grid>
            <Grid item xs={5} sm={4} md={3}>{t("modal_step3_data_2")}</Grid>
            <Grid item xs={7} sm={8} md={7}>{redeemInfo?.mobileNumber}</Grid>
            <Grid item xs={5} sm={4} md={3}>{t("modal_step3_data_3")}</Grid>
            <Grid item xs={7} sm={8} md={7}>{redeemInfo?.email}</Grid>
            {/* <Grid item xs={5} sm={4} md={3}>{t("modal_step3_data_4")}</Grid>
            <Grid item xs={7} sm={8} md={7}>{redeemInfo?.redeemCode}</Grid> */}
        </Grid>
        </Box>)
    }
    
    return (<>
    <Grid
    container
    direction="column"
    justifyContent="start"
    alignItems="center"
    sx={{overflowY: "auto", minHeight: "100vh", maxHeight:"100vh", p:10, bgcolor:"#000", flexWrap: "nowrap",}}
    >
        <Typography align="center" variant="h3">{t("modal_step3_title")}</Typography>
        <Typography align="center" mt={2} mb={4}>{t("modal_step3_body1")}</Typography>
        <Box
            component="img"
            src={"/nftImage/"+props.redeemedNft.src}
            sx={{ objectFit: "cover", maxWidth: "50%", my: 4 }}
          />
        <Typography align="center" mb={4}>{props.redeemedNft.nftCategory +" "+ props.redeemedNft.nftName}</Typography>
        <ConfirmData/>
        <Typography align="center" >{t("modal_step3_body2")}</Typography>
        <Typography align="center" py={2}>{t("modal_step3_note")}</Typography>
        
        <Box my={10}>
            <Button variant="outlined" color="white" sx={{my:1}} onClick={()=>{closeParentModal()}}>{t("back_to_home")}</Button>
        </Box>
        <Box >
            <Typography variant="caption" color="gray300.main" align="center">{t("modal_step3_remind")}</Typography>
        </Box>
    </Grid>
    
    
    </>)
}

import {  Box,  Typography ,Grid} from "@mui/material";
import { useTranslation } from "react-i18next";
import ConnectWalletBtn from "../../../common/ConnectWalletBtn";

export default function Component(props) {
    const { t } = useTranslation();
    return (<>
    
    <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    sx={{overflowY: "auto", minHeight:"100vh", p:10, bgcolor:"#000"}}
    >
        <Typography align="center" variant="h3">{t("modal_step1_title")}</Typography>
        <Typography align="center" my={2}>{t("modal_step1_body1")}</Typography>
        <Box sx={{py:7}}>
        <ConnectWalletBtn/>
        </Box>  
        <Box component="a" href="https://phantom.app/" ><Typography >{t("create_a_wallet")}</Typography></Box>
    </Grid>
    
    </>)
}

import { Container, Grid} from "@mui/material";
// import { useEffect, useState } from "react";
// import { useTranslation } from "react-i18next";
// import {useMediaQuery} from "@material-ui/core";
// import { useTheme } from '@mui/material/styles';

export default function NftPreview(props) {
// const { t, i18n } = useTranslation();
// const matchDownMd = useMediaQuery(useTheme().breakpoints.down('sm'));
const ImageListItemSet = () => {
    let content = []
    // let imageCount = (matchDownMd)?4:6
    let imageCount = 6
    for(let i=1; i<=imageCount; i++){
        content.push(
        <Grid item xs={6} sm={4} key={'nftPreview-'+i}>
        {/* <Box component='img' src={"/media/.png"} sx={{objectFit: 'fill', maxWidth: "90vw", mt:4}}/> */}
        {/* <img
            src={`/media/0${i}.png`}
            srcSet={`/media/0${i}.png`}
            alt={'nftPreview-'+i}
            loading="lazy"
            style={{width: '100%'}}
        /> */}
        <div className="flip-box"   style={{width: '100%'}}>
            <div className="flip-box-inner">
                <div className="flip-box-front">
                    <img
                    src={`/media/0${i}.png`}
                    srcSet={`/media/0${i}.png`}
                    alt={'nftPreview-'+i}
                    loading="lazy"
                    style={{width: '100%'}}
                    /> 
                </div>
                <div className="flip-box-back">
                    <img
                    src={`/media/0${i}_flip.png`}
                    srcSet={`/media/0${i}_flip.png`}
                    alt={'nftPreview-'+i}
                    loading="lazy"
                    style={{width: '100%'}}
                    /> 
                </div>
            </div>
        </div>
        
        </Grid>)
    }
    return content
}
return (
    <>
    
    <Container maxWidth="false" >
        {/* <ImageList sx={{ width: "100vw" }} cols={matchDownMd ? 2 : 3 } >
            <ImageListItemSet />
        </ImageList> */}
        <Grid container spacing={0}  rowSpacing={0} >
           <ImageListItemSet />
        </Grid>
    </Container>
    
       
    </>
)
}

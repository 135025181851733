import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={18.765}
      height={18.236}
      viewBox="0 0 18.765 18.236"
      stroke="#fff"
      {...props}
    >
      <g transform="translate(-680.382 -768.5)" fill="none" strokeWidth={1}>
        <path
          data-name="Line 56"
          transform="translate(689.765 768.5)"
          d="M0 0L0 17"
        />
        <path data-name="Path 173" d="M680.735 777l9.029 9.029 9.028-9.029" />
      </g>
    </svg>
  )
}

export default SvgComponent

import {
  Typography,
  Box,
  Grid,
  ListItem,
  Modal,
  IconButton,
  Fade,
  Divider
} from "@mui/material";
// import Palette from "../styles/theme/palette";
import { useTranslation } from "react-i18next";
import SvgRightArrow from "../components/icon/SvgRightArrow.jsx";
import SvgClose from "../components/icon/SvgClose.jsx";
// import SvgPopup from "../media/UI/icon/SvgPopup.jsx";
// import SvgClose from "../media/UI/icon/SvgClose.jsx";
import {  useDispatch, useSelector } from "react-redux";
import {  updateWhyModalOpenState, whyModalOpenState } from "../reducers/commonSlice";

import React, { useEffect, useState } from "react";

const printContent = (contentSection) => {
  return [
    contentSection.map((item, index) => {
      let type = item.type || "text";
        
      return ContentFunctionMap[type](item, index);
    }),
  ];
};

const GetImage = (item, index) => {
  return (
    <Box sx={item.style} key={"image-" + index}>
      <Box
        component="img"
        src={item.src}
        sx={
          { objectFit: "cover", maxWidth: "70vw",...(item.style || {}) }
        }
      />
    </Box>
  );
};

const GetDivider = (item, index) => {
    return <Divider key={"divider-" + index} />;
}

const GetModal = (item, index) => {
  const dispatch = useDispatch();
  const isOpen = useSelector(whyModalOpenState);
  let [modelContent] = useState(item.content || []);
  let modalId = item.id || `modal-${index}`
  
  
  let [open, setOpen] = useState(isOpen);
  let handleOpen = () => {
    setOpen(true);
  };

  let handleClose = () => {
    dispatch(updateWhyModalOpenState(false))
    setOpen(false);
  };
  
  // useEffect(()=>{
  //   // setOpen(isOpen);
  //   dispatch(updateWhyModalOpenState(open))
    
  //   //localStorage.setItem(modalId, open);
  // }, [dispatch, open])
  
  useEffect(()=>{
    setOpen(isOpen);
    //dispatch(updateWhyModalOpenState(open))
    
    //localStorage.setItem(modalId, open);
  }, [isOpen])
  
  

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100vw", sm: "70vw" },
    height: "100vh",
    // maxWidth: { xs: "100vw", sm: "70vw" },
    overflowY: "hidden",
  };

  const btnStyle = {
    position: "fixed",
    top: "2rem",
    right: "0",
    padding: 1,
  };
  // endIcon={<SvgPopup fill={Palette.secondary.main} />}
  return (
    <Box key={"modal" + index}>
        <Box component="div" onClick={handleOpen}>
            <IconButton key={"modalBtn" + index}  sx={{ p: 0 }} id={"ga-"+modalId}>
                <SvgRightArrow/>
            </IconButton>
        </Box>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ p: 4 }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={handleClose}
              sx={btnStyle}
            >
              <SvgClose fill="white"/>
            </IconButton>
            <Grid
              container
              direction="column"
              justifyContent="start"
              alignItems="center"
              sx={{
                overflow: "auto",
                height: "100vh",
                flexWrap: "nowrap",
                p: 10,
                bgcolor: "#000",
              }}
            >
              {printContent(modelContent, index)}
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

const getTypography = (contentNode, index) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  return (
    <Typography
      key={"typography-" + index}
      variant={contentNode.variant}
      color={contentNode.color}
      sx={contentNode.style || {}}
    >
      {t(contentNode.caption)}
    </Typography>
  );
};

const getTypographySection = (contentSection) => {
  // if(!contentSection) return console.warn("ContentHelper.getTypographySection: contentSection is undefined");
  // if(typeof contentSection != 'array') return console.warn("ContentHelper.getTypographySection: contentSection is not Array");
  return [
    contentSection.map((item, index) => {
      return getTypography(item, index);
    }),
  ];
};

const getTypographyContent = (contentObject) => {
  if (!contentObject)
    return console.warn(
      "ContentHelper.getTypographyContent: content is undefined"
    );
  if (!("content" in contentObject))
    return console.warn(
      "ContentHelper.getTypographyContent: content.content is undefined"
    );

  return contentObject.content.map((section) => {
    return [
      section.map((item, index) => {
        return getTypography(item, index);
      }),
    ];
  });
};

const getBulletList = (contentList, tab = "") => {
  return (
    <Box component="ol" sx={{ pl: 1 }}>
      {contentList.bullets.map((item, index) => {
        return (
          <Box key={"bullet-" + tab + index}>
            <ListItem sx={{ display: "list-item", fontSize: 0.624 }}>
              {getTypography(item, index)}
            </ListItem>
          </Box>
        );
      })}
    </Box>
  );
};

const ContentFunctionMap = {
  modal: GetModal,
  text: getTypography,
  image: GetImage,
  divider: GetDivider
};

const ContentHelper = {
  getTypographyContent,
  getTypographySection,
  getTypography,
  getBulletList,
  printContent,
};

export default ContentHelper;

import { Container, Box, Typography, Grid} from "@mui/material";
// import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentHelper from "../../../helper/ContentHelper";
import RoadMapContent from "./RoadMap.json";

import Palette from "../../../styles/theme/palette";

export default function RoadMap(props) {
    const { t } = useTranslation();
    
    const Content = (props) => {
        if(!props.contentSection) return 
        let section = props.contentSection;
        return (
        <Grid item xs={12} sm={3} pr={2} py={3} sx={{}}>
            <Grid container alignItems="center" columnSpacing={4}>

                <Grid item sm={12} xs={6} >
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                    >
                    {ContentHelper.getTypographySection(section.filter(item => item.variant !== 'body2'))} 
                    </Grid>
                </Grid>
                <Grid item sm={12} xs={6} >{ContentHelper.getTypographySection(section.filter(item => item.variant === 'body2'))} </Grid>
                {/* <Grid item xs={12} sm={3}></Grid> */}
            </Grid>
        </Grid>)
    }
    
    const RedeemContent = (props) => {
        if(!props.contentSection) return 
        let section = props.contentSection;
        return (
        <Grid item xs={12} sm={2.4} pr={2} py={3} >
            <Grid container alignItems="center" columnSpacing={4}>
                <Grid item sm={12} xs={6} >{ContentHelper.getTypographySection(section.filter(item => item.variant !== 'body2'))} </Grid>
                <Grid item sm={12} xs={6} >{ContentHelper.getTypographySection(section.filter(item => item.variant === 'body2'))} </Grid>
                {/* <Grid item xs={12} sm={3}></Grid> */}
            </Grid>
        </Grid>)
    }
return (
    
    
    <Container maxWidth="lg" >
        <Box sx={{py:10, px:2}}>
        
            <Typography variant="h2" sx={{pb:2}}>{t("home_roadmap")}</Typography>
            {/* Stage 1 */}
            <Box sx={{borderBottom: "1px solid "+Palette.gray300.main}}>
                <Typography variant="h4">{t("home_drop")}</Typography>
            </Box>
            
            <Grid container>
                
                    {RoadMapContent.content.stage_1.map((section, index)=>{
                        return (<Content key={'stage1-'+index} contentSection={section}/>)
                    })}
        
            </Grid>
            <Typography variant="body2" mt={3}>{t("home_drop_note")}</Typography>
            <Box sx={{height:"10vh"}}/>
            {/* Stage 2 */}
            <Box sx={{borderBottom: "1px solid "+Palette.gray300.main}}>
                <Typography variant="h4">{t("home_reward")}</Typography>
            </Box>
            <Grid container >
            {RoadMapContent.content.stage_2.map((section, index)=>{
                return <RedeemContent key={'stage2-'+index} contentSection={section}/>
            })}
            </Grid>
            {/* Stage 3 */}
            
            <Box sx={{height:"10vh"}}/>
            
            <Box sx={{borderBottom: "1px solid "+Palette.gray300.main}}>
                <Typography variant="h4">{t("art_exhibition")}</Typography>
            </Box>
            <Grid container >
            {RoadMapContent.content.stage_3.map((section, index)=>{
                return (
                    <Grid item xs={12} sx={{mt:4}}>
                        <Grid container alignItems="center" columnSpacing={4}>
                            <Grid item xs={12} >
                                <Grid container direction={"row"} justifyContent="start" >
                                    {ContentHelper.getTypographySection(section.filter(item => item.variant !== 'body2'))} 
                                </Grid>
                            </Grid>
                            <Grid item xs={12} >{ContentHelper.getTypographySection(section.filter(item => item.variant === 'body2'))} </Grid>
                            {/* <Grid item xs={12} sm={3}></Grid> */}
                        </Grid>
                    </Grid>)
                
            })}
            </Grid>
            
            
        </Box>
    </Container>
    
)
}

import { Container, Box, Typography, Grid} from "@mui/material";
import ResponsiveCarousel from "../../common/ResponsiveCarousel"
// import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export default function RexKooIntro(props) {
const { t } = useTranslation();
return (
    <>
    <Box sx={{backgroundColor: 'cadmiumPurple.main'}}>
    <Container maxWidth="false" >
        <Grid
        container 
        alignItems="center"
        >
            <Grid item sm={6} px={2} sx={{py:{xs:10, md:2}, px:{xs:2, sm:10}}}>
                <Typography variant="h3">
                    {t("rex_koo_intro_title")}
                </Typography>
                
                <Typography my={4}>
                    {t("rex_koo_intro_body")}
                </Typography>
            </Grid>   
            <Grid item sm={6}>
                <ResponsiveCarousel/>
            </Grid>   
        </Grid>               
    </Container>
    </Box>
    </>
)
}

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "./ResponsiveCarousel.css"
import { Carousel } from 'react-responsive-carousel';
import {Box, Button} from "@mui/material";



import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

export default function ResponsiveCarousel(props) {
    
    const imageSet =  (props.imageSet)?props.imageSet:['rex_koo/01.png','rex_koo/02.png','rex_koo/03.png','rex_koo/04.png','rex_koo/05.png','rex_koo/06.png']
    const scopeCss = (props.scopeCss)?props.scopeCss:{
    ".carousel .controlDots":{
          textAlign: "right",
        }
    }
    
    const arrowStyles = {
        position: 'absolute',
        zIndex: 2,
        background: 'black',
        border: "0.5px solid #fff",
        top: 'calc(50% - 15px)',
        padding: "1rem 0",
        minWidth: "2.5rem",
        cursor: 'pointer',
    };
    
    const renderArrowPrev = (onClickHandler, hasPrev, label) =>
        hasPrev && (
            <Button variant="outlined" color="white" onClick={onClickHandler} style={{ ...arrowStyles, left: 0 }}>
                <ArrowBackIosNewIcon/>
            </Button>
        )
    
    const renderArrowNext = (onClickHandler, hasNext, label) =>
        hasNext && (
            <Button variant="outlined" color="white" onClick={onClickHandler}  style={{ ...arrowStyles, right: 0 }}>
                <ArrowForwardIosIcon/>
            </Button>
        )
    
    return (<Box>
        <Carousel 
        renderArrowPrev = {renderArrowPrev}
        renderArrowNext = {renderArrowNext}
        style={scopeCss} showThumbs={false} showArrows={false} showStatus={false} autoPlay={true} infiniteLoop={true} stopOnHover={true} interval={2000} {...props.props} >
            {imageSet.map((_, i) => (<Box key={'image-'+i} component="img" src={`/media/${_}`} alt="image01" />))}
        </Carousel>
    </Box>)
}

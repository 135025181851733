import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { Suspense, useEffect } from "react";
import "./styles/globals.css";
import { install } from "ga-gtag";
// Required Core Stylesheet

import Layout from "./components/layout.js";

import { CssBaseline } from "@mui/material";

// import Test from "./pages/test";
import Home from "./pages/Home.jsx";

import ThemeDemo from "./pages/ThemeCheck.jsx";
// import Admin from "./pages/Admin.jsx";
import SpecialCollection from "./SpecialCollection";

import MyThemeProvider from "./components/MyThemeProvider";
import MyWalletProvider from "./components/MyWalletProvider";

const Loader = () => (
  <div className="App">
    <div>loading...</div>
  </div>
);
const tagManagerArgs = {
  gtmId: "GTM-T9N5P3W",
};

// here app catches the suspense from page in case translations are not yet loaded

const App = () => {
  useEffect(() => {
    ReactGA.initialize("G-44311D7M55");
    ReactGA.send("pageview");

    TagManager.initialize(tagManagerArgs);

    install("DC-6529925");
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <MyThemeProvider>
        <CssBaseline />
        <MyWalletProvider>
          <Layout>
            <BrowserRouter>
              <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/mint" element={<SpecialCollection />}></Route>

                <Route path="/theme" element={<ThemeDemo />}></Route>
                {/* <Route path='/admin' element={<Admin/>}></Route> */}
                <Route path="*" element={<div>404</div>}></Route>
                {/* <Route path='/' element={<div>123</div>}></Route>
                  <Route path='/test' element={<Test/>}></Route>
                  <Route path='/home' element={<Home/>}></Route> */}
              </Routes>
            </BrowserRouter>
          </Layout>
        </MyWalletProvider>
      </MyThemeProvider>
    </Suspense>
  );
};

export default App;

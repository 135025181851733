import {
  
  Box,
  Grid,
  Modal,
  IconButton,
  Fade,
  
} from "@mui/material";
import SvgRightArrow from "../../icon/SvgRightArrow.jsx";
import SvgClose from "../../icon/SvgClose.jsx";
import React, { useState } from "react";

export default function CommonModal ({ children, customBtn }) {
    const defaultBtn = () => {
        return (<IconButton key={"modalBtn"}  sx={{ p: 0 }}>
                <SvgRightArrow/>
            </IconButton>)
    }
    
  const ModalBtn = customBtn || defaultBtn ;
  let [open, setOpen] = useState(false);

  let handleOpen = () => {
    console.log("handleOpen");
    setOpen(true);
  };

  let handleClose = () => {
    setOpen(false);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "100vw", sm: "70vw" },
    height: "100vh",
    // maxWidth: { xs: "100vw", sm: "70vw" },
    overflowY: "hidden",
  };

  const btnStyle = {
    position: "fixed",
    top: "2rem",
    right: "0",
    padding: 1,
  };
  // endIcon={<SvgPopup fill={Palette.secondary.main} />}
  return (
    <Box key={"modal"}>
        <Box  onClick={handleOpen}>
            <ModalBtn key={"modalBtn"} />  
        </Box>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ p: 4 }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="close"
              onClick={handleClose}
              sx={btnStyle}
            >
              <SvgClose fill="white"/>
            </IconButton>
            <Grid
              container
              direction="column"
              justifyContent="start"
              alignItems="center"
              sx={{
                overflow: "auto",
                height: "100vh",
                flexWrap: "nowrap",
                p: 10,
                bgcolor: "#000",
              }}
            >
              {children}
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
};

import Palette from "../palette.js";

// const effect =
//   "box-shadow 0.2s ease-in-out, margin-top 0.2s ease-in-out, opacity 0.2s ease-in-out";
const Element = {
  // variants: [
  //     {
  //         props: { variant: "outlined" },
  //         style: {
  //             border: `1px solid #000`,
  //             color: Palette.black.main,
  //             whiteSpace: "nowrap",
  //             borderRadius: "0.4rem",
  //             boxShadow: `0 3px 0 #000`,
  //             transition: effect,
  //             "&:hover": {
  //                 marginTop: "-6px",
  //                 boxShadow: `0 6px 0 #000`,
  //             },

  //         },
  //     },
  // ],
  styleOverrides: {
    root: {
      padding: "0.7rem 1.55rem",
      // margin: "0 0.5rem",
      borderRadius: "0!important",
      borderColor: Palette.border.main,
      whiteSpace: "nowrap",
      boxShadow: "none",
      "&.Mui-disabled": {
        color: Palette.gray400.main,
        border: "1px solid " + Palette.gray400.main,
      },
      // ":hover": {
      //   boxShadow: "0px 2px 20px #C61B02 ",
      // },
    },
  },
};

export default Element;

// import React, {useState} from 'react';


// import {AppBar, Grid, Box, Typography, Tab, Container, Button, Divider, IconButton, Drawer } from '@mui/material';
import {AppBar,Container,Button,Grid,Box } from '@mui/material';

import { useEffect } from 'react';
import { useTranslation } from "react-i18next";

// import { useEffect } from 'react';
import SvgNarsIcon from "../icon/SvgNarsIcon.jsx"
import ConnectWalletBtn from "./ConnectWalletBtn";

import {  useDispatch } from "react-redux";
import {  updateLangState } from "../../reducers/commonSlice";
import { useMediaQuery, useTheme } from '@material-ui/core';

const ResponsiveAppBar = () =>     
{
  const matchXs = useMediaQuery(useTheme().breakpoints.down('xs'));
  useEffect(()=>{
    console.debug("Header loaded")
    
  },[])
  const {  i18n } = useTranslation();
  
  const dispatch = useDispatch();
  
  const switchLangMap= {
      en : {label: "中", lang:"zh", fontSize: "14px"},
      zh : {label:"ENG", lang:"en",  fontSize: "14px" },
  }
  const appBarBtnStyle = {
    backgroundColor: "#000!important",
    fontSize: switchLangMap[i18n.language]?.fontSize, 
    borderTop: "0px!important", 
    borderRight: "0px!important", 
    height:"3rem", 
    width:"2rem",
    borderColor:"#fff!important", 
    color:"#fff!important", 
    "&:hover": {borderTop: "0px", borderRight: "0px",backgroundColor: "#000"}}  
    
  const switchLang = (lang) => {
    i18n.changeLanguage(lang)
    dispatch(updateLangState(lang))
    localStorage.setItem('lang', lang)
  }
  
  const LangBtn = (props) => {
    
    let afterLangObj = switchLangMap[i18n.language]
    
    return (
      <Button variant="outlined" color="white" sx={appBarBtnStyle} onClick={()=>switchLang(afterLangObj.lang)}>{afterLangObj.label}</Button>
    )
  }
  
    
    return (
    <>
    <Box sx={{pt:1, position:'absolute',top:(matchXs)?-10: 0, left:-10, cursor: "pointer"}}>
      <SvgNarsIcon height={(matchXs)?"100px": "150px"} width={(matchXs)?"100px": "150"}  onClick={() => {window.location.href= "/"}}/>
      
    </Box>
    <AppBar position="fixed" sx={{width: "100vw", zIndex: 900}}>
      <Container maxWidth="false" >
        <Grid container direction="row" justifyContent="flex-end" alignItems="flex-start" >
          <LangBtn/>
          <ConnectWalletBtn className="app-bar-wallet-adapter-button"/>
        </Grid>
      </Container>
    </AppBar>
    
    </>
  );
};
export default ResponsiveAppBar;

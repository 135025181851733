// import Palette from "../palette.js";

const Element = {
  styleOverrides: {
    root: {
      zIndex: 1000,
    },
  },
};

export default Element;

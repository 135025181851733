import axios from "axios";
const baseURL = process.env.REACT_APP_API_BASE_URL;
const RedeemNft = (postData, callback = () => {}, handleProgress) => {
  localStorage.setItem("redeemInfo", JSON.stringify(postData));
  axios.post(baseURL + "redeem/", postData).then((response) => {
    callback(response.data.isSuccess, response.data.msg, response.data.data);
  });
};

export default RedeemNft;

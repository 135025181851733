// import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';
import  './library/i18n';

import './styles/fonts/HelveticaNeue/HelveticaNeueLTStd-Lt.otf';
import './styles/fonts/HelveticaNeue/HelveticaNeueLTStd-Th.otf';
import './styles/fonts/HelveticaNeue/HelveticaNeueLTStd-UltLt.otf';
import './styles/fonts/HelveticaNeue/MHeiHK-Light.ttf';

import React from 'react';

import { Provider } from "react-redux";
import { store } from "./store";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider> 
  </React.StrictMode>
  ,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

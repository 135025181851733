import * as React from "react"

function SvgClose(props) {
  return (
    <svg
      width={48}
      height={48}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 25.207L40.824 42.03l1.414-1.414-16.824-16.824 16.41-16.41-1.414-1.414L24 22.379 7.59 5.969 6.176 7.383l16.41 16.41L5.762 40.617l1.414 1.414L24 25.207z"
        
      />
    </svg>
  )
}

export default SvgClose

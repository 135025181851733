import {  Button,  Typography ,Grid, TextField} from "@mui/material";
import { useTranslation } from "react-i18next";

import { useState, useEffect } from "react";

import RedeemNft from "./_api";

import { useWallet } from "@solana/wallet-adapter-react";
import gtag from 'ga-gtag';

export default function Component({handleProgress, setRedeemedNft}) {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { t, i18n } = useTranslation();
    const {publicKey} = useWallet();
    const [redeemInfo, setRedeemInfo] = useState({
        name: "",
        mobileNumber: "",
        email:"",
        // redeemCode: "",
        walletAddress: "",
        lang: i18n.language
    })
    
    const handleChange = (e) => {
        setRedeemInfo(prev => ({...prev, [e.target.name]: e.target.value}))        
    }

    useEffect(()=>{
        setRedeemInfo(prev => ({...prev, walletAddress: publicKey?.toBase58()}))
    }, [publicKey])
    
    useEffect(()=>{
        setRedeemInfo(prev =>({...prev, lang: i18n.language}))
    }, [i18n.language])
    
    return (<>
    <Grid
    container
    direction="column"
    justifyContent="center"
    alignItems="center"
    sx={{overflowY: "auto", minHeight:"100vh", p:10, bgcolor:"#000"}}
    >
        <Grid container sx={{py:5, maxHeight:"90vh"}} spacing={3}>
            <Grid item xs={12}>
                <Typography variant="h3" align="center">{t("modal_step2_title")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography align="center" py={2} >{t("modal_step2_body1")}</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth name="name" label={t("name")} variant="standard" color="white" onChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth sx={{ "WebkitAppearance": "none"}} type="number" name="mobileNumber" label={t("mobile_number")} variant="standard" color="white" onChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
                <TextField fullWidth type="email" name="email" label={t("email")} variant="standard" color="white" onChange={handleChange} />
            </Grid>
            {/* <Grid item xs={12}>
                <TextField fullWidth name="redeemCode" label={t("sms_code")} variant="standard" color="white" onChange={handleChange} />
                <Typography variant="caption" color="gray300.main">{t("modal_step2_remind")}</Typography>
            </Grid> */}
            <Grid item xs={12}>
                <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center">
                    <Button id="ga-acquire" variant="outlined" color="white" disabled={isSubmitted} sx={{my:1}} onClick={()=>{
                        setIsSubmitted(true)
                        RedeemNft(redeemInfo, (isSuccess, msg, data) => {
                            if (isSuccess) {
                              handleProgress(3);
                              setRedeemedNft(data)
                              return;
                            }
                            setIsSubmitted(false)
                            alert(msg);
                            return;
                        });
                        gtag('event', 'conversion', {
                        'allow_custom_scripts': true,
                        'u1': '/',
                        'send_to': 'DC-6529925/powicon/00cam0+standard'
                        });
                    }
                    }>{t("acquire")}</Button>
                </Grid>
                
            </Grid>
            
        </Grid>  
        
        
    </Grid>
    
    </>)
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  lang: "zh",
  wallet: "",
  whyModalOpen: false,
  redeemModalOpen: false,
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    updateLangState: (state, value) => {
      state.lang = value.payload;
      // console.log(state.lang);
    },
    updateWalletState: (state, value) => {
      state.wallet = value.payload;
      //console.log(state.wallet);
    },
    updateWhyModalOpenState: (state, value) => {
      state.whyModalOpen = value.payload;
      // console.log(state.whyModalOpen);
    },
    updateRedeemModalOpenState: (state, value) => {
      state.redeemModalOpen = value.payload;
    },
  },
});

export const {
  updateLangState,
  updateWalletState,
  updateWhyModalOpenState,
  updateRedeemModalOpenState,
} = commonSlice.actions;

export const langState = (state) => state.common.lang;
export const walletState = (state) => state.common.wallet;
export const whyModalOpenState = (state) => state.common.whyModalOpen;
export const redeemModalOpenState = (state) => state.common.redeemModalOpen;

export default commonSlice.reducer;

import { Container, Box, Typography, Accordion, AccordionSummary, AccordionDetails, Grid, Button } from "@mui/material";
// import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ContentHelper from "../../../helper/ContentHelper.jsx";
import FaqContent from "./Faq.json";
import Palette from "../../../styles/theme/palette";

import SvgPlus from "../../icon/SvgPlus.jsx";
import SvgLine from "../../icon/SvgLine.jsx";
import { useState } from "react";

import {  useDispatch } from "react-redux";
import {  updateWhyModalOpenState } from "../../../reducers/commonSlice";

export default function Faq() {
const dispatch = useDispatch();
const { t } = useTranslation();

function Content(props) {
    let content = props.contentSection || [];

    return ContentHelper.getTypographySection(content);
}
function ImageContent(props) {
    let content = props.contentSection || [];

    return (<Box sx={{mt:0.5}}>{ContentHelper.printContent(content)}<br/></Box>);
}

function FaqCard(props) {
    
    const [expanded, setExpanded] = useState("");
    // const [key] = useState(props.key ?? "");
    
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    let question = props.content.filter(item => item.type === "question");
    // let tempAns = props.content.filter(item => item.type !== "question");
    let answerList = props.content.filter(item => !("type" in item));
    let imageList = props.content.filter(item => item.type === "image");
    
    return (
        <Accordion 
        expanded={expanded === props.refs}
        onChange={handleChange(props.refs)}
        id="ga-faq"
        square={true}
        sx={{ py: 1,boxShadow: "none", background:"black", mb: 0, ":before": { backgroundColor: "transparent" }, borderBottom: "1px solid "+Palette.gray300.main}}>
            <AccordionSummary  expandIcon={ expanded === props.refs? <SvgLine sx={{}}/>:<SvgPlus sx={{}}/>} aria-controls="panel1a-content">
                <Content contentSection={question} />
            </AccordionSummary>
            <AccordionDetails >
                <Grid container alignItems="flex-start" columnSpacing={1}>
                    {answerList.map((ans, index) => {
                        return (
                            <Grid item key={'ans'+index}xs={12} sm={(imageList.length > 0) ?4:12}>
                                {(imageList.length > 0) ? <ImageContent contentSection={[imageList[index]]}  />:null}
                                {/* ans */}
                                <Grid container alignItems="flex-start">
                                    <Content contentSection={[ans]}/>
                                    
                                    {(ans?.flag === "moreDetail" )?(<Button variant="text" color="white" sx={{p:0, mt:0.5}}  onClick={()=>{dispatch(updateWhyModalOpenState(true))}} >
                                        <Typography variant="body1">{t("more_detail")}</Typography>
                                    </Button>):null }
                                    
                                </Grid>
                        
                            </Grid>
                        )
                    })}
                    
                </Grid>
              
            </AccordionDetails>
        </Accordion>
      
    );
}
return (

    <>
    
     <Container maxWidth="lg" sx={{borderTop: "1px solid "+Palette.gray300.main}}>
             
        <Box sx={{py:10, px:2}}>
            <Typography variant="h2" sx={{pb:2}}>{t("home_FAQ")}</Typography>
            
            {FaqContent.content.map((section, index) => {
                return <FaqCard key={"faq_" + index} refs={"faq_" + index} content={section} />;
            })}
        </Box>
    </Container>
    </>
)
}

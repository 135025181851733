import {  Box} from "@mui/material";
import { useEffect, useState } from "react";
import "./_style.css"
export default function RollingBanner(props) {
    const [isReverse, setIsReverse] = useState(false);
    const [caption, setCaption] = useState("12");
    useEffect(() => {
        if(props.caption){
            setCaption(props.caption) 
        }
    },[props.caption])
    useEffect(() => {
        if(props.reverse){
            setIsReverse(true) 
        }
      
    },[props.reverse])

return (
    <>
    
    <Box sx={{p:0}} className={"marquee" + (isReverse?' reverse':'')}>
        <Box className="marquee__inner" aria-hidden="true">
            <span className="">{caption}</span>
            <span className="">{caption}</span>
            <span className="">{caption}</span>
            <span className="">{caption}</span>
            <span className="">{caption}</span>
            <span className="">{caption}</span>
            
        </Box>
    </Box>
    </>
)
}

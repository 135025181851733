import { Container, Typography, Grid, Link,Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import CommonModal from "./CommonModal";
// import Palette from "../../styles/theme/palette";

export default function Footer() {
const { t, i18n } = useTranslation();
const privacyPolicyLink = {
    en: "https://www.narscosmetics.com.hk/en/privacy.html",
    zh: "https://www.narscosmetics.com.hk/zh/privacy.html"
}

const TncModal = () => {
    const tncFile = i18n.language === "en" ? "NARS_TnC_en.html" : "NARS_TnC_zh.html";
    // return (<CommonModal text="222"></CommonModal>)
    return (
    <CommonModal customBtn={ModalBtn}>
        <Box component="iframe" 
        sx={{border: "none"}}
    src={`../../doc/${tncFile}`} title="TERMS AND CONDITIONS"  width="100%" height="100%"></Box>
    </CommonModal>)
}

const ModalBtn = () => { 
    return (<Box component="div" sx={linkStyle} >
            <Typography variant="body2">{t("tnc")}</Typography>
        </Box>)
}

const linkStyle = {
    color:"gray300.main",
    textDecoration: "none",
    cursor: "pointer",
    ":hover": {
        color: "white.main"
    }
}
/** */
return (
    <footer>
    <Container maxWidth="lg">
        <Grid container
          direction="row"
          justifyContent="center"
          alignItems="center" py={4} px={2}>
                
                <Box>
                <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center" >
                    <Link sx={linkStyle} href={privacyPolicyLink[i18n.language]} target="_blank">
                        <Typography variant="body2">{t("privacy_policy")}</Typography>
                    </Link>&nbsp;|&nbsp;
                    <TncModal/>
                </Grid>
                    
                </Box>
            
                <Typography variant="body2" align="center" sx={{marginLeft: "1rem!important"}}>{t("nars_rights_caption")}</Typography>
            
        </Grid>
    </Container>
    </footer>
)
}

// import Palette from "../palette.js";

const Element = {
  styleOverrides: {
    root: {
      boxShadow: "none",
      backgroundColor: "transparent!important",
      //borderBottom: `1px solid #000000`,
    },
  },
};

export default Element;

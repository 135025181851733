const typographyZh = {
  fontFamily: ["MHeiHK"].join(","),
  // htmlFontSize: 10,
  // fontWeightBold: 900,
  banner: {
    fontSize: "2.6rem",
    letterSpacing: "7.56px",
    fontFamily: "HelveticaNeue-Ultra-Light",
    height: "2.7rem",
    color: "#B60E22",
    textShadow: "0px 0px 10px #C61B02 ",
  },
  shadowBanner: {
    fontSize: "5.5rem",
    fontFamily: "MHeiHK",
    color: "#C11b0E",
    textShadow: "1px 2px 5px #8c0f01",
  },
  h1: {
    fontSize: "5.5rem",
    fontFamily: "MHeiHK",
  },
  h2: {
    fontSize: "3.9rem",
    fontFamily: "MHeiHK",
  },
  h3: {
    fontSize: "2.8rem",
    fontFamily: "MHeiHK",
  },
  h4: {
    fontSize: "1.8rem",
    fontFamily: "MHeiHK",
  },
  h5: {
    fontSize: "20px",
    fontFamily: "HelveticaNeue-Ultra",
  },
  body1: {
    fontSize: "1rem",
    fontFamily: "MHeiHK",
  },
  body2: {
    fontSize: "0.9rem",
    fontFamily: "MHeiHK",
  },
  caption: {
    fontFamily: "MHeiHK",
    fontSize: "0.77rem",
    lineHeight: 1.65,
    letterSpacing: "0em",
  },
  button: {
    fontFamily: "MHeiHK",
    fontSize: "0.8rem",
  },
};
// function setTypography() {
//   console.log(i18n.language, "language");
//   return i18n.language ? typographyZh : typographyEn;
// }

// const typography = setTypography();
export default typographyZh;

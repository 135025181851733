import { ThemeProvider } from "@mui/material/styles";
import ThemeOption from "../styles/theme/themeOption";
import typographyEn from "../styles/theme/typographyEn";
import typographyZh from "../styles/theme/typographyZh";

import { createTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { langState } from "../reducers/commonSlice";
import { useEffect, useState } from "react";

// import { useState, useEffect } from "react";

// import { useTranslation } from "react-i18next";

export default function Layout({ children }) {
  // const { i18n } = useTranslation();

  const lang = useSelector(langState);
  // const lang = localStorage.getItem("lang");
  const [Theme, setTheme] = useState(createTheme(ThemeOption));
  useEffect(() => {
    if (lang === "en") {
      ThemeOption.typography = typographyEn;
    } else {
      ThemeOption.typography = typographyZh;
    }

    setTheme(createTheme(ThemeOption));
  }, [lang]);
  return (
    <>
      <ThemeProvider theme={Theme}>{children}</ThemeProvider>
    </>
  );
}

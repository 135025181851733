import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={29.837}
      height={1}
      viewBox="0 0 29.837 1"
      {...props}
    >
      <path
        data-name="Line 92"
        transform="translate(0 .5)"
        fill="none"
        stroke="#fff"
        strokeWidth={1}
        d="M0 0L29.837 0"
      />
    </svg>
  )
}

export default SvgComponent

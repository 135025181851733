import Palette from "../palette.js";

const Element = {
  styleOverrides: {
    root: {
      borderBottom: "1px solid " + Palette.white.main,

      // "label + &": {
      //     marginTop: "1.5rem",
      //     color: Palette.text.secondary,
      // },
      // "div.Mui-error ": {
      //     border: "1px solid " + Palette.error.main,
      // },
      // "& .MuiInputBase-input": {
      //     borderRadius: 2,
      //     position: "relative",
      //     backgroundColor: Palette.background.paper,
      //     border: "1px solid " + Palette.border_2.main,

      //     padding: "0.6rem 12px",

      //     // transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
      //     // Use the system font instead of the default Roboto font.
      // },
      //   "& .Mui-focused": {
      //     borderBottom: "1px solid " + Palette.primary.main,
      //   },
      // fieldset: {
      //     border: "0px",
      // },
      // "& .Mui-error ": {
      //     marginLeft: "0rem",
      //     "& ~ label": {
      //         color: "#fff",
      //     },
      // },
    },
  },
};

export default Element;

import MuiAppBar from "./MuiAppBar.js";
import MuiButton from "./MuiButton.js";
import MuiDivider from "./MuiDivider.js";
// import MuiTab from "./MuiTab.js";
// import MuiBox from "./MuiBox.js";
// import MuiInputLabel from "./MuiInputLabel.js";
import MuiTextField from "./MuiTextField.js";
import MuiModal from "./MuiModal.js";
// import MuiSelect from "./MuiSelect.js";

const components = {
  MuiAppBar: MuiAppBar,
  MuiButton: MuiButton,
  MuiDivider: MuiDivider,
  MuiModal: MuiModal,
  // MuiTab: MuiTab,
  // MuiBox: MuiBox,
  // MuiInputLabel: MuiInputLabel,
  MuiTextField: MuiTextField,
  // MuiSelect: MuiSelect,
};

export default components;

const palette = {
  type: "light",
  black: {
    main: "#000000",
  },
  white: {
    main: "#ffffff",
  },
  primary: {
    main: "#F24B43",
  },
  secondary: {
    main: "#4D4D4D",
  },
  cadmiumPurple: {
    main: "#B60E22",
  },
  lightCadmiumPurple: {
    main: "#C61B02",
  },
  venetianRed: {
    main: "#FD4F47",
  },
  venetianRed2: {
    main: "#FF494A",
  },
  englishVermillion: {
    main: "#E2695E",
  },
  gray100: {
    main: "#E5E5E5",
  },
  gray200: {
    main: "#D5D5D5",
  },
  gray300: {
    main: "#B9B9B9",
  },
  gray400: {
    main: "#707070",
  },
  gray500: {
    main: "#4D4D4D",
  },
  border: {
    main: "rgba(255, 255, 255, 0.30)",
  },
  error: {
    main: "#cd4443",
  },
  success: {
    main: "#43cd49",
  },
  background: {
    default: "#000000",
    paper: "#4D4D4D",
    venetianRed: "#FD4F47",
    venetianRed2: "#FF494A",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "#E5E5E5",
    hover: "#E5E5E5",
  },
};

export default palette;

import {Typography, Box, Grid} from "@mui/material";
import TypographySet from "../styles/theme/typographyEn";
import Palette from "../styles/theme/palette";
// import { useTranslation } from "react-i18next";



const ThemeCheck = () => {
    // const { t, i18n } = useTranslation();
    return(
    <>
        <Grid container spacing={2}>
            <Grid item xs={6}>
            {Object.keys(TypographySet).map((type, index) => {
                if(type === "fontFamily") return ''
                return (
                    <Box key={'typography-'+index}>
                        
                        <Typography variant={type}>This is [{type}] </Typography>
                        
                    </Box>);
            })}
            </Grid>
            <Grid item xs={6}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                >
                {Object.keys(Palette).map((type, index) => {
                    if(type === "type") return ''
                    return (
                        
                        <Box key={'palette-'+index} sx={{background: Palette[type].main, width:"100px", height:"100px"}}>
                            <Typography variant="caption" pl={0.2} color="#000">{type}</Typography>
                        </Box>);
                })}
                </Grid>
            </Grid>
        </Grid>
    </>)
}

export default ThemeCheck;

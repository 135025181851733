import Navbar from "./common/Navbar.jsx";
import Footer from "./common/Footer.jsx";
import { Container } from "@mui/material";

export default function Layout({ children }) {
  return (
    /* <Box sx={{ bgcolor: "", height: "100vh" }}> */
    <Container maxWidth={false}>
      <Navbar />
      {children}
      <Footer />
    </Container>
    // </Box>
  );
}

import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={29.837}
      height={29.837}
      viewBox="0 0 29.837 29.837"
      {...props}
    >
      <g
        data-name="Group 217"
        transform="translate(-391.5 -6968.891)"
        fill="none"
        stroke="#fff"
        strokeWidth={1}
      >
        <path
          data-name="Line 92"
          transform="translate(391.5 6983.81)"
          d="M0 0L29.837 0"
        />
        <path
          data-name="Line 93"
          transform="rotate(90 -3281.237 3687.654)"
          d="M0 0L29.837 0"
        />
      </g>
    </svg>
  )
}

export default SvgComponent

export let redeemModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { xs: "100vw", sm: "70vw" },
  maxWidth: { xs: "100vw", sm: "70vw" },
  overflowY: "hidden",
  /* bgcolor: 'background.paper', */
  /* boxShadow: 24, */
};

export let closeBtn = {
  position: "fixed",
  top: "2rem",
  right: "0",
  padding: 1,
};

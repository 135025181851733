import { Container, Button, Typography, Grid, Box } from "@mui/material";
// import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SvgDownArrow from "../../icon/SvgDownArrow.jsx";

import RedeemModal from "../RedeemModal";

export default function HeroSection(props) {
  const ScrollTag = (props) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mb={1}
      >
        <Box sx={{ px: 0.8 }}>
          <SvgDownArrow />
        </Box>
        <Typography variant="caption" pb={0.4}>
          {props.label}
        </Typography>
      </Grid>
    );
  };
  const { t } = useTranslation();
  return (
    <Box sx={{ px: 0, mt:18}}>
      <Container maxWidth="md">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            component="img"
            src="/media/heroImage.gif"
            sx={{ objectFit: "cover", maxWidth: "100vw", mt: 4 }}
          />
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            px={2}
          >
            {/* <Typography variant="banner" align="center" sx={{fontSize: {md: "4.4rem"}, height:{md:"4.4rem"}, mb:1}}>{t("POWERICON")}</Typography> */}
            <Box
              component="img"
              src="/powericon.png"
              sx={{
                objectFit: "contain",
                maxWidth: "90vw",
                textAlign: "center",
              }}
            />
            <Typography align="center">{t("home_hero_content")}</Typography>

            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              px={2}
              spacing={1}
            >
              <Grid item>
                <RedeemModal />
              </Grid>
              <Grid item sx={{display: {xs:"none", sm:"block"}}}>
                <Button
                  variant="outlined"
                  color="white"
                  sx={{ my: 1 }}
                  onClick={() => {
                    window.location.href = "/mint";
                  }}
                >
                  {t("home_mint_btn")}
                </Button>
              </Grid>
            </Grid>
            <ScrollTag label={t("home_hero_Scroll")} />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

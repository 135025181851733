import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      data-name="Group 27"
      xmlns="http://www.w3.org/2000/svg"
      width={276.044}
      height={497.391}
      viewBox="0 0 276.044 497.391"
      {...props}
    >
      <defs>
        <clipPath id="a">
          <path
            data-name="Rectangle 25"
            fill="none"
            d="M0 0H276.044V497.391H0z"
          />
        </clipPath>
      </defs>
      <path
        data-name="Path 94"
        d="M2.343 119.944h4.67v34.4h48.42v-34.4H60.1v75.814h-4.67v-37.375H7.014v37.375H2.343z"
        transform="translate(-.061 -3.14)"
        fill="#fff"
      />
      <path
        data-name="Path 95"
        d="M143.84 119.944h5.837l47.89 69.55h.211v-69.55h4.673v75.814h-5.521l-48.205-70.08h-.214v70.08h-4.67z"
        transform="translate(-3.766 -3.14)"
        fill="#fff"
      />
      <g data-name="Group 26">
        <g data-name="Group 25" clipPath="url(#a)" fill="#fff">
          <path
            data-name="Path 96"
            d="M210.345 158.227a48.826 48.826 0 012.389-15.342 38.894 38.894 0 016.955-12.792 33.219 33.219 0 0111.2-8.76 34.323 34.323 0 0115.234-3.241 36.316 36.316 0 0111.149 1.7 29.659 29.659 0 0116.564 12.954 28.79 28.79 0 013.825 10.619H273.2q-2.656-9.983-9.559-15.611t-17.412-5.627a29.32 29.32 0 00-13.479 2.973 29.018 29.018 0 00-9.769 7.965 35.2 35.2 0 00-5.945 11.517 45.9 45.9 0 00-2.02 13.645 46.4 46.4 0 001.966 13.645 32.8 32.8 0 005.841 11.2 27.892 27.892 0 009.713 7.592 31.26 31.26 0 0013.7 2.811 31.855 31.855 0 0012.213-2.227 25.847 25.847 0 009.182-6.264 26.37 26.37 0 005.68-9.717 34.644 34.644 0 001.593-12.689h-27.925v-4.036h31.96v37.164H274.9v-16.134h-.211a23.244 23.244 0 01-4.144 7.485 25.6 25.6 0 01-6.528 5.627 32.953 32.953 0 01-8.388 3.556 38.294 38.294 0 01-24.848-1.806 32.7 32.7 0 01-11.2-8.387 35.947 35.947 0 01-6.9-12.478 49.264 49.264 0 01-2.335-15.343"
            transform="translate(-5.507 -3.092)"
          />
          <path
            data-name="Path 97"
            d="M2.54 200.711h4.67v43.643l47.674-43.643h6.053l-34.613 31.538 36.738 44.276h-5.838l-34.3-41.092-15.713 14.336v26.756H2.54z"
            transform="translate(-.067 -5.255)"
          />
          <path
            data-name="Path 98"
            d="M133.333 238.571a51.167 51.167 0 01-2.307 15.554 37.983 37.983 0 01-6.666 12.635 31.221 31.221 0 01-10.682 8.441 32.286 32.286 0 01-14.341 3.08 32.607 32.607 0 01-14.388-3.08 31.026 31.026 0 01-10.729-8.441 37.921 37.921 0 01-6.67-12.635 53.6 53.6 0 010-31.112 38 38 0 016.67-12.635 31.117 31.117 0 0110.729-8.441 32.662 32.662 0 0114.388-3.08 32.34 32.34 0 0114.341 3.08 31.314 31.314 0 0110.682 8.441 38.059 38.059 0 016.666 12.635 51.191 51.191 0 012.307 15.558m-63.675 0a46.362 46.362 0 002.006 13.8 34.446 34.446 0 005.814 11.306 28.458 28.458 0 009.325 7.7 26.761 26.761 0 0012.534 2.865 26.479 26.479 0 0012.488-2.865 28.653 28.653 0 009.274-7.7 34.6 34.6 0 005.818-11.306 48.5 48.5 0 000-27.61 34.641 34.641 0 00-5.818-11.306 28.665 28.665 0 00-9.274-7.7 26.525 26.525 0 00-12.488-2.865 26.807 26.807 0 00-12.537 2.868 28.47 28.47 0 00-9.325 7.7 34.486 34.486 0 00-5.814 11.306 46.388 46.388 0 00-2.006 13.807"
            transform="translate(-1.708 -5.206)"
          />
          <path
            data-name="Path 99"
            d="M133.333 157.734a51.166 51.166 0 01-2.307 15.554 37.983 37.983 0 01-6.666 12.635 31.221 31.221 0 01-10.682 8.441 32.286 32.286 0 01-14.341 3.08 32.607 32.607 0 01-14.388-3.08 31.026 31.026 0 01-10.729-8.441 37.921 37.921 0 01-6.67-12.635 53.6 53.6 0 010-31.112 38 38 0 016.67-12.635 31.117 31.117 0 0110.729-8.441 32.661 32.661 0 0114.388-3.08 32.339 32.339 0 0114.341 3.08 31.314 31.314 0 0110.682 8.441 38.058 38.058 0 016.666 12.635 51.192 51.192 0 012.307 15.558m-63.675 0a46.362 46.362 0 002.006 13.8 34.447 34.447 0 005.814 11.306 28.456 28.456 0 009.325 7.7 26.761 26.761 0 0012.534 2.865 26.479 26.479 0 0012.488-2.865 28.651 28.651 0 009.274-7.7 34.6 34.6 0 005.818-11.306 48.5 48.5 0 000-27.61 34.64 34.64 0 00-5.818-11.306 28.663 28.663 0 00-9.274-7.7 26.525 26.525 0 00-12.488-2.865 26.807 26.807 0 00-12.537 2.868 28.468 28.468 0 00-9.325 7.7 34.484 34.484 0 00-5.814 11.306 46.389 46.389 0 00-2.006 13.807"
            transform="translate(-1.708 -3.09)"
          />
          <path
            data-name="Path 100"
            d="M143.911 200.711h5.837l47.89 69.55h.211v-69.55h4.674v75.814H197l-48.2-70.08h-.214v70.08h-4.671z"
            transform="translate(-3.768 -5.255)"
          />
          <path
            data-name="Path 101"
            d="M210.345 238.994a48.826 48.826 0 012.389-15.342 38.9 38.9 0 016.955-12.792 33.218 33.218 0 0111.2-8.76 34.323 34.323 0 0115.234-3.241 36.317 36.317 0 0111.149 1.7 29.659 29.659 0 0116.564 12.954 28.789 28.789 0 013.825 10.619H273.2q-2.656-9.983-9.559-15.611t-17.412-5.627a29.319 29.319 0 00-13.484 2.973 29.017 29.017 0 00-9.769 7.965 35.2 35.2 0 00-5.945 11.517 45.894 45.894 0 00-2.02 13.645 46.4 46.4 0 001.966 13.645 32.8 32.8 0 005.841 11.2 27.892 27.892 0 009.713 7.592 31.26 31.26 0 0013.7 2.811 31.857 31.857 0 0012.213-2.227 25.849 25.849 0 009.182-6.264 26.372 26.372 0 005.68-9.717 34.645 34.645 0 001.593-12.689h-27.92v-4.036h31.96v37.164H274.9v-16.134h-.211a23.244 23.244 0 01-4.144 7.485 25.6 25.6 0 01-6.528 5.627 32.95 32.95 0 01-8.388 3.556 38.292 38.292 0 01-24.848-1.805 32.7 32.7 0 01-11.2-8.388 35.948 35.948 0 01-6.9-12.477 49.264 49.264 0 01-2.335-15.343"
            transform="translate(-5.507 -5.206)"
          />
          <path
            data-name="Path 102"
            d="M164.424 427.559l-101.268.141a33.857 33.857 0 014.144-17.2 61.52 61.52 0 0111.2-14.375 127.172 127.172 0 0115.489-12.681q8.566-6 17.028-11.891 8.681-5.881 16.925-12a119.777 119.777 0 0014.716-12.788 60.825 60.825 0 0010.423-14.271 38.192 38.192 0 00.968-32.06 32.359 32.359 0 00-8.625-11.363 37.9 37.9 0 00-13.092-6.979 57.088 57.088 0 00-16.672-2.48q-11.722-.115-20.154 3.345a35.579 35.579 0 00-13.8 9.7 39.457 39.457 0 00-7.884 14.967 71.01 71.01 0 00-2.62 19.1l-4.42-.043q.45-24.76 12.639-38.247t36.279-13.25a62.518 62.518 0 0118.111 2.715 41.809 41.809 0 0114.521 7.773 36.953 36.953 0 019.717 12.7 42.926 42.926 0 01-3.368 40.216 93.014 93.014 0 01-16.883 18.852 209.8 209.8 0 01-21.94 16.488q-11.8 7.736-22.151 15.488a121.62 121.62 0 00-17.727 15.973q-7.38 8.221-8.356 17.939l96.847-.185z"
            transform="translate(-1.653 -7.205)"
          />
          <path
            data-name="Path 103"
            d="M277.369 382.658a77.419 77.419 0 01-9.64 24.9 52.36 52.36 0 01-17.635 17.632q-10.906 6.636-27.26 6.479-16.147-.156-27.024-7A52.062 52.062 0 01178.4 406.7a77.285 77.285 0 01-9.156-25.078 144.2 144.2 0 01.538-56.163 77.384 77.384 0 019.636-24.893 53.024 53.024 0 0117.751-17.742q11.008-6.746 27.155-6.6 16.36.162 27.13 7.117a53.452 53.452 0 0117.293 18.081 77.45 77.45 0 019.16 25.074 144.008 144.008 0 01-.537 56.163m-3.576-53.766a76.565 76.565 0 00-8.176-23.742 50.313 50.313 0 00-15.865-17.512q-10-6.838-25.47-6.99t-25.608 6.5a50.182 50.182 0 00-16.2 17.206 76.347 76.347 0 00-8.629 23.58 136.509 136.509 0 00-.492 51.3 76.342 76.342 0 008.176 23.738 50.229 50.229 0 0015.861 17.512q10 6.843 25.478 6.982t25.6-6.494a50.269 50.269 0 0016.2-17.205 76.553 76.553 0 008.629-23.576 136.517 136.517 0 00.492-51.3"
            transform="translate(-4.366 -7.232)"
          />
          <path
            data-name="Path 104"
            d="M30.178 474.515L5.221 437.95H.384l27.569 40.145v28.921h4.257v-28.921l27.76-40.145h-4.739z"
            transform="translate(-.01 -11.466)"
          />
          <path
            data-name="Path 105"
            d="M32.394 507.208h-4.647v-29.057L0 437.75h5.315l24.851 36.411 24.951-36.411h5.22l-.215.307-27.728 40.094zm-4.256-.392H32v-28.788l.035-.05 27.547-39.837h-4.257L30.166 474.86l-.161-.238-24.9-36.48H.745l27.394 39.887z"
            transform="translate(0 -11.461)"
          />
          <path
            data-name="Path 106"
            d="M137.9 437.95l-27.749 69.038v-3.647H67.495v-30.373h39.66v-3.676h-39.66v-27.667h42.175v-3.675H63.239v69.066h51.447l8.8-22.151h33.084l8.7 22.151h4.547l-27.273-69.066zm-13.059 43.24l15.381-39.08h.195l14.8 39.08z"
            transform="translate(-1.656 -11.466)"
          />
          <path
            data-name="Path 107"
            d="M109.951 507.209H63.039V437.75h46.823v4.067H67.686v27.274h39.661v4.068H67.686v29.982h44.333l-1.677 2.823 27.421-68.214h4.912l.05.123 27.382 69.336h-4.969l-.05-.124-8.656-22.029h-32.814l-8.8 22.152h-4.865zm55.455-.392h4.125l-27.125-68.675h-4.374l-27.6 68.675h4.12l8.8-22.152H156.7l.05.123zm-101.974 0h46.519v-3.284H67.295v-30.765h39.66v-3.284h-39.66v-28.059h42.176v-3.284h-46.04zm92.063-25.435h-30.948l.1-.268 15.431-39.2h.465l.05.127zm-30.371-.391h29.8L140.312 442.4z"
            transform="translate(-1.65 -11.461)"
          />
          <path
            data-name="Path 108"
            d="M210.358 473.642v-.192c9.188-1.547 14.8-8.414 14.8-17.7 0-13.349-9.77-17.8-21.861-17.8h-28.826v69.066h4.256v-31.438H203.3c12.091 0 15.381 5.419 16.25 12.094.774 6.671-.773 14.605 1.838 19.344h4.742c-2.806-3.675-1.646-11.414-2.321-18.573s-3.191-13.637-13.446-14.8m-31.636-1.743v-30.275H203.3c9.286 0 17.607 3.967 17.607 14.414 0 11.125-7.547 15.864-17.607 15.864z"
            transform="translate(-4.568 -11.466)"
          />
          <path
            data-name="Path 109"
            d="M226.52 507.208h-5.258l-.057-.1c-1.7-3.088-1.663-7.409-1.624-11.986a61.806 61.806 0 00-.235-7.431c-.725-5.55-3.2-11.922-16.056-11.922h-24.372v31.438h-4.647V437.75h29.019c10.062 0 22.059 3.122 22.059 18 0 9.263-5.473 16.022-14.333 17.777 10.377 1.456 12.394 8.7 12.977 14.9.211 2.258.245 4.562.273 6.793.065 4.736.127 9.21 2.012 11.679zm-5.027-.392h4.247c-1.747-2.619-1.8-6.985-1.866-11.6-.031-2.219-.061-4.517-.273-6.759-.618-6.56-2.681-13.422-13.273-14.624l-.172-.019v-.534l.162-.027c9.028-1.52 14.639-8.23 14.639-17.508 0-14.552-11.782-17.6-21.667-17.6h-28.627v68.675h3.864v-31.442h24.763c13.157 0 15.7 6.556 16.448 12.263a61.831 61.831 0 01.235 7.485c-.038 4.47-.073 8.7 1.521 11.69m-18.2-34.722h-24.767v-30.669h24.763c11.148 0 17.8 5.461 17.8 14.609 0 10.208-6.49 16.06-17.8 16.06m-24.372-.391h24.372c11.064 0 17.412-5.711 17.412-15.669 0-8.9-6.509-14.218-17.412-14.218h-24.372z"
            transform="translate(-4.563 -11.461)"
          />
          <path
            data-name="Path 110"
            d="M256.6 470.019c-11.7-2.416-23.505-4.256-23.505-15.377 0-11.417 11.124-14.7 18.958-14.7 12.769 0 21.668 5.9 22.152 17.7h4.256c-.678-15.186-12-21.376-26.408-21.376-10.93 0-23.216 5.127-23.216 18.38 0 13.154 11.8 15.957 23.507 18.377 11.8 2.42 23.6 4.451 23.6 16.153 0 12-11.7 15.769-20.022 15.769-13.639 0-25.731-5.707-24.862-20.8h-4.26c-.194 17.8 12.961 24.471 29.116 24.471 10.542 0 24.279-5.127 24.279-19.444 0-13.733-11.8-16.732-23.6-19.152"
            transform="translate(-5.938 -11.422)"
          />
          <path
            data-name="Path 111"
            d="M255.914 508.807c-10.219 0-18.257-2.665-23.246-7.7-4.1-4.147-6.145-9.858-6.064-16.967v-.192h4.658l-.011.207c-.327 5.653 1.16 10.269 4.424 13.721 4.178 4.429 11.364 6.867 20.239 6.867 7.366 0 19.828-3.28 19.828-15.573 0-11.176-10.853-13.391-22.343-15.734l-1.1-.227c-11.095-2.292-23.665-4.888-23.665-18.569 0-12.83 11.76-18.575 23.412-18.575 7.581 0 13.967 1.739 18.472 5.031 5.073 3.707 7.807 9.267 8.134 16.533l.011.2h-4.654l-.008-.189c-.458-11.129-8.461-17.512-21.956-17.512-8.652 0-18.761 3.8-18.761 14.509 0 10.424 10.515 12.566 21.644 14.836l1.7.349c13 2.665 23.761 5.96 23.761 19.344a17.406 17.406 0 01-8.276 15.258 30.568 30.568 0 01-16.2 4.382M227 484.336c-.038 6.909 1.962 12.458 5.953 16.49 4.912 4.966 12.854 7.589 22.966 7.589a30.142 30.142 0 0015.987-4.325 17.024 17.024 0 008.1-14.924c0-13.088-10.619-16.329-23.446-18.96l-1.705-.35c-11.292-2.3-21.956-4.478-21.956-15.22 0-11 10.315-14.9 19.152-14.9 13.66 0 21.79 6.448 22.339 17.7h3.863c-.411-7.934-4.408-20.984-26.2-20.984-11.456 0-23.02 5.623-23.02 18.184 0 13.358 12.4 15.923 23.35 18.185l1.1.223c11.652 2.377 22.658 4.624 22.658 16.121 0 12.6-12.707 15.965-20.219 15.965-8.983 0-16.272-2.481-20.527-6.986-3.3-3.494-4.823-8.141-4.539-13.81z"
            transform="translate(-5.933 -11.417)"
          />
          <path
            data-name="Path 112"
            d="M242.054 56.266c-1.442-.359-2.907-.722-4.4-1.1-1.755-.419-3.514-.816-5.26-1.213a128.5 128.5 0 01-16.333-4.453c3.763-4.839 5.7-10.975 5.545-18.127-.247-10.334-3.415-17.731-9.674-22.445 7.993-4.282 17.817-4.846 22.208-4.846 22.785 0 34.69 10.078 35.382 29.952l.018.474h4.074l-.021-.512C272.618 11.754 258.975 0 234.14 0c-5.167 0-17.056.725-26.216 6.449-5.262-2.571-12.076-3.831-20.547-3.831h-45.7v61.86L117.2 2.618h-4.695l-25.559 62.84V2.62h-4.061v72.828l-8.53 20.969-66.33-93.59-.149-.207H2.494v111.288h4.071V8.083l65.9 92.98-5.226 12.845h4.618l3.531-8.72 6.033 8.512.148.207h5.375V77.6h54.727v36.306l4.028-.025.053-27.442 10.883 27.16.125.308h4.467l-15.474-39.116V62.158h41.154c24.321 0 28.77 9.453 28.77 28.073 0 3.285.111 12.78 1.274 19.238-13.393-4.89-20.2-15.794-20.2-32.662v-.492h-4.061l-.007.484c-.2 11.938 3.062 21.412 9.705 28.168 7.436 7.566 19.168 11.563 33.92 11.563 19.636 0 39.45-9.324 39.45-30.151 0-21.744-14.874-25.437-33.7-30.112M200 28.917c0-8.354 3.454-13.889 8.214-17.584 6.156 3.976 9.235 10.53 9.336 19.744a25.253 25.253 0 01-5.507 16.7C204.862 44.2 200 38.741 200 28.917m-4.072 0c0 11.55 5.4 17.945 13.1 22.027-5.488 4.608-13.338 7.143-22.428 7.143h-40.847V6.7h42.39c6.407 0 11.724.868 16.016 2.585-4.818 4.293-8.237 10.507-8.237 19.631m17.065 23.858c5.421 2.2 11.588 3.628 17.694 5.029l2.731.629c2 .49 4.061.949 6.148 1.412 15.794 3.51 32.128 7.139 32.128 26.374 0 18.127-17.769 26.242-35.382 26.242a62.739 62.739 0 01-14.876-1.64c-1.2-4.748-1.677-14.21-1.677-20.108 0-17.674-3.327-27.945-20.55-30.823a32.525 32.525 0 0013.784-7.114M140.6 73.531H88.2l26.643-66.372zM82.885 86.685v21.765l-5.61-7.915z"
            transform="translate(-.065)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SvgComponent

import { Container, Box, Typography, Grid, Button} from "@mui/material";
// import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";


export default function AboutPowerIcon(props) {
const { t } = useTranslation();
const Content = ()=> {
    let content = [];
    for(let i=1; i<=3; i++){
         content.push(<Typography mb={2} key={"aboutContent_"+i} align="center">{t("home_About_"+(i))}</Typography>)
    }
    return content
}
return (
    <Box sx={{backgroundColor: 'background.venetianRed', pt:4, pb:10}}>
        <Container maxWidth="md" >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
            <Box component='img' src="/aboutPoweicon.png" sx={{objectFit: 'contain', maxWidth: "90vw", textAlign:"center"}}/>
            
            <Box sx={{py:2, px:{xs:2,sm:10}}}>
                <Content/>
            </Box>
            <Button
                  variant="outlined"
                  color="white"
                  sx={{ my: 1, minWidth: ""}}
                  onClick={() => {
                    window.open("https://opensea.io/collection/narshk-20-nft-powericon", '_blank');
                  }}
                >
                  {t("home_opensea")}
                </Button>
        </Grid>               
    
        </Container>
    </Box>
)
}


import "@solana/wallet-adapter-react-ui/styles.css";
import { useTranslation } from "react-i18next";
import React, { useMemo } from 'react';
import { Grid, Typography} from "@mui/material";
import {  useWallet } from '@solana/wallet-adapter-react';

import "./ConnectWalletBtnStyle.css";
import {
  WalletModalProvider,
  WalletMultiButton,
} from "@solana/wallet-adapter-react-ui";
import gtag from 'ga-gtag';

export default function Component({className}) {
    const { t } = useTranslation();
    const { publicKey, wallet } = useWallet();
    const base58 = useMemo(() => publicKey?.toBase58(), [publicKey]);
    // useEffect(() => {
    //     console.log("base58", base58);
    // }, [base58]);
    
    const content = useMemo(() => {
        if (!wallet || !base58) return t("connect_wallet");
        // localStorage.setItem("wallet", base58);
        return base58.slice(0, 4) + '..' + base58.slice(-4);
    }, [t, wallet, base58]);
    return( 
    
    <WalletModalProvider>
        
            <WalletMultiButton className={className} id="ga-connection-wallet">
            
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => {
                    gtag('event', 'conversion', {
                      'allow_custom_scripts': true,
                      'send_to': 'DC-6529925/powicon/00cam001+standard'
                    })
                  }}
                >
                
                {/* <i className="wallet-adapter-button-start-icon">
                    <img src="data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjM0IiB3aWR0aD0iMzQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGxpbmVhckdyYWRpZW50IGlkPSJhIiB4MT0iLjUiIHgyPSIuNSIgeTE9IjAiIHkyPSIxIj48c3RvcCBvZmZzZXQ9IjAiIHN0b3AtY29sb3I9IiM1MzRiYjEiLz48c3RvcCBvZmZzZXQ9IjEiIHN0b3AtY29sb3I9IiM1NTFiZjkiLz48L2xpbmVhckdyYWRpZW50PjxsaW5lYXJHcmFkaWVudCBpZD0iYiIgeDE9Ii41IiB4Mj0iLjUiIHkxPSIwIiB5Mj0iMSI+PHN0b3Agb2Zmc2V0PSIwIiBzdG9wLWNvbG9yPSIjZmZmIi8+PHN0b3Agb2Zmc2V0PSIxIiBzdG9wLWNvbG9yPSIjZmZmIiBzdG9wLW9wYWNpdHk9Ii44MiIvPjwvbGluZWFyR3JhZGllbnQ+PGNpcmNsZSBjeD0iMTciIGN5PSIxNyIgZmlsbD0idXJsKCNhKSIgcj0iMTciLz48cGF0aCBkPSJtMjkuMTcwMiAxNy4yMDcxaC0yLjk5NjljMC02LjEwNzQtNC45NjgzLTExLjA1ODE3LTExLjA5NzUtMTEuMDU4MTctNi4wNTMyNSAwLTEwLjk3NDYzIDQuODI5NTctMTEuMDk1MDggMTAuODMyMzctLjEyNDYxIDYuMjA1IDUuNzE3NTIgMTEuNTkzMiAxMS45NDUzOCAxMS41OTMyaC43ODM0YzUuNDkwNiAwIDEyLjg0OTctNC4yODI5IDEzLjk5OTUtOS41MDEzLjIxMjMtLjk2MTktLjU1MDItMS44NjYxLTEuNTM4OC0xLjg2NjF6bS0xOC41NDc5LjI3MjFjMCAuODE2Ny0uNjcwMzggMS40ODQ3LTEuNDkwMDEgMS40ODQ3LS44MTk2NCAwLTEuNDg5OTgtLjY2ODMtMS40ODk5OC0xLjQ4NDd2LTIuNDAxOWMwLS44MTY3LjY3MDM0LTEuNDg0NyAxLjQ4OTk4LTEuNDg0Ny44MTk2MyAwIDEuNDkwMDEuNjY4IDEuNDkwMDEgMS40ODQ3em01LjE3MzggMGMwIC44MTY3LS42NzAzIDEuNDg0Ny0xLjQ4OTkgMS40ODQ3LS44MTk3IDAtMS40OS0uNjY4My0xLjQ5LTEuNDg0N3YtMi40MDE5YzAtLjgxNjcuNjcwNi0xLjQ4NDcgMS40OS0xLjQ4NDcuODE5NiAwIDEuNDg5OS42NjggMS40ODk5IDEuNDg0N3oiIGZpbGw9InVybCgjYikiLz48L3N2Zz4K" alt="Phantom icon"/>
                </i> */}
                
                <Typography align="center" variant="button">
                {content}
                </Typography>
                </Grid>
            
            
            </WalletMultiButton >
          {/* Your app's components go here, nested within the context providers. */}
        </WalletModalProvider>
    )
        
}

import { Container, Box, Typography, Grid} from "@mui/material";
// import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// import Palette from "../../../styles/theme/palette";

export default function NarsissistDay(props) {
const { t } = useTranslation();
return (
     <Box sx={{backgroundColor: 'background.venetianRed2',  py:10}}>
        <Container maxWidth="lg" >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
            <Typography variant="h2" sx={{fontSize:{xs:"2rem",sm:"3rem", md:"3.9rem"}}} align="center" color="lightCadmiumPurple.main" fontFamily="HelveticaNeue-Ultra-Light">{t("celebrate_the_iconic")}</Typography>
            <Box component='img' src="/narsissistDay.png" sx={{objectFit: 'contain', maxWidth: "90vw", textAlign: "center"}}/>
            {/* <Divider  variant="middle" /> */}
            <Box sx={{p:2}}>
                <Typography align="center">{t("home_celcbrate_body")}</Typography>
            </Box>
        </Grid>               
    
        </Container>
        
    </Box>

    
)
}

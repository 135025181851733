import { Modal, Fade, Button, Box, IconButton} from "@mui/material";
import { useEffect, useState } from "react"
import { useWallet } from '@solana/wallet-adapter-react';
import { useTranslation } from "react-i18next";
import * as Style from "./RedeemModalStyle"

import SvgClose from "../../icon/SvgClose.jsx";   

import ConnectWallet from "./ConnectWallet";
import RedeemForm from "./RedeemForm";
import Thankyou from "./Thankyou";

import gtag from 'ga-gtag';

import {  useDispatch, useSelector } from "react-redux";
import {  updateRedeemModalOpenState, redeemModalOpenState } from "../../../reducers/commonSlice";

export default function HeroSection(props) {
    const dispatch = useDispatch();
    const isOpen = useSelector(redeemModalOpenState);
    const {wallet, publicKey} = useWallet();
    const { t } = useTranslation();
    const [modalProgress, setModalProgress] = useState(1);
    const [redeemedNft, setRedeemedNft] = useState({});
    let [open, setOpen] = useState(isOpen);
    
    let handleOpen = () => {
        // console.log("handleOpen");
        gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'u1': '/',
        'send_to': 'DC-6529925/powicon/00cam00+standard'
        })
        setOpen(true);
    }
    
    let handleClose = () => {   
        dispatch(updateRedeemModalOpenState(false))
        setOpen(false)
    }
    useEffect(()=>{
        if(wallet && publicKey){
            setModalProgress(2)
        }else{
            setModalProgress(1)
        }
        
    }, [wallet, publicKey] )
    useEffect(()=>{
    setOpen(isOpen);
    //dispatch(updateWhyModalOpenState(open))
    
    //localStorage.setItem(modalId, open);
    }, [isOpen])
    
    const ModalContent = () => {
        if(modalProgress === 1){
            return <ConnectWallet/>
        }
        if(modalProgress === 2){
            return <RedeemForm handleProgress={setModalProgress} setRedeemedNft={setRedeemedNft}/>
        }
        if(modalProgress === 3){
            return <Thankyou closeModal={handleClose} redeemedNft={redeemedNft}/>
        }
    }
    
return (
    <>
        <Button id="ga-redeem" variant="outlined" color="white" sx={{my:1}} onClick={handleOpen}>{t("home_hero_btn")}</Button>
        
        <Box>
            
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{p:4}}
                
            >
                <Fade in={open}>
                    
                    <Box  sx={Style.redeemModal}>
                        <IconButton 
                        edge="start"
                        color="inherit"
                        aria-label="close"
                        onClick={handleClose}
                        sx={Style.closeBtn}
                        >
                            <SvgClose fill="#fff"/>
                        </IconButton>
                        <Box>
                            <ModalContent/>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
        </Box>
    </>
)
}

// import {Typography, Box, Button} from "@mui/material";
// import TypographySet from "../styles/theme/typography";
import { useTranslation } from "react-i18next";


import HeroSection from "../components/home/HeroSection";
import AboutPowerIcon from "../components/home/AboutPowerIcon";
import SpacialEditionPreview from "../components/home/SpecialEditionPreview";
import Faq from "../components/home/Faq";
import NarsissistDay from "../components/home/NarsissistDay";
import NftPreview from "../components/home/NftPreview";
import RexKooIntro from "../components/home/RexKooIntro";
import RoadMap from "../components/home/RoadMap";
import RollingBanner from "../components/home/RollingBanner";
import WhyPlayPower from "../components/home/WhyPlayPower";

import gtag from 'ga-gtag';
import { useEffect } from "react";


const Home = () => {
    const { t } = useTranslation();
    
    useEffect(() => {
        console.log("Welcome to the home page");
        gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'u1': '[url]',
        'send_to': 'DC-6529925/powicon/00cam0+standard'
        },[]);
    })
    
    return(
    <>
        
        <HeroSection/>
        <AboutPowerIcon/>
        <RollingBanner caption={t("SPECIAL EDITION POWERICON #PLAYYOURPOWER  ")}/>
        <SpacialEditionPreview/>
        {/* <RollingBanner reverse={true} caption={t("PLAYYOURPOWER")}/> */}
        <WhyPlayPower/>
        <RexKooIntro/>
        <RollingBanner caption={t("PLAYYOURPOWER")}/>
        <NftPreview/>
        <RollingBanner caption={t("PLAYYOURPOWER")} />
        <NarsissistDay/>
        <RoadMap/>
        <Faq/>
        
        
    </>)
}

export default Home;

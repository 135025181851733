
import { Container, Box, Typography, Grid, Link, IconButton} from "@mui/material";
// import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ContentHelper from "../../../helper/ContentHelper.jsx";
import WhyPlayPowerContent from "./WhyPlayPower.json";

import SvgRightArrow from "../../icon/SvgRightArrow.jsx"

import Palette from "../../../styles/theme/palette"

export default function WhyPlayPower(props) {
const { t } = useTranslation();

const Description = ()=> {
    let content = [];
    for(let i=1; i<=1; i++){
         content.push(<Typography mb={2}  key={"aboutContent_"+i} align="center" sx={{}}>{t("home_Why_"+(i))}</Typography>)
    }
    return content
}

const Content = (props)=> {
    let section = props.section ?? []
    let index = props.index 
    //borderTop: (index === 0)?`1px solid ${Palette.gray400.main}`:'none' 
    
    let gridStyle = {borderBottom: (index === WhyPlayPowerContent.content.length-1)?"none":`1px solid ${Palette.gray400.main}`, }
    
    return (
    <Grid container 
      alignItems="center"
      key={"whyPlayContent"} rowSpacing={0} columnSpacing={1} p={3} sx={gridStyle }>
            
            <Grid  item xs={12} md={3} pt={1} >
                {ContentHelper.getTypography(section[0])}
            </Grid>
            <Grid  item xs={10} md={7} py={1} >
                {ContentHelper.getTypography(section[1])}
            </Grid>
            <Grid  item xs={2} md={2} >
                <ExtraContent content={section[2]??null}/>
            </Grid>
            
        </Grid>
        )

}

const ExtraContent = ({content}) => {
    if(content === null) return null
    
    if(content.type === "link"){
        return (
        <Link href={content.link} target="_blank" id="ga-order">
            <IconButton>
                <SvgRightArrow/>
            </IconButton>
        </Link>)
    }
    
    if(content.type === "modal"){
        return (
            ContentHelper.printContent([content])
        )
    }
    return null
    
    
}
return (<>
    <Box sx={{backgroundColor: 'primary.main', pt:4, pb:10}}>
        <Container maxWidth="lg" >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
            <Box component='img' src="/whyPoweicon.png" sx={{objectFit: 'contain', maxWidth: "90vw", textAlign: "center"}}/>
            {/* <Divider  variant="middle" /> */}
            <Box sx={{p:2}}>
                <Description/>
            </Box>
        </Grid>               
    
        </Container>
        
    </Box>
    <Box sx={{mb:10, }}>
        <Container maxWidth="lg" >
            {WhyPlayPowerContent.content.map((section, index)=>{
                return <Content key={"whyPlay-"+index} section={section} index={index}/>
            })}
        </Container>
    </Box>
    </>
)
}

import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16.121}
      height={29.414}
      viewBox="0 0 16.121 29.414"
      {...props}
    >
      <path
        data-name="Path 138"
        d="M.708.707l14 14-14 14"
        fill="none"
        stroke="#fff"
        strokeWidth={2}
      />
    </svg>
  )
}

export default SvgComponent

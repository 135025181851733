// import Palette from "../palette.js";

// const effect =
//   "box-shadow 0.2s ease-in-out, margin-top 0.2s ease-in-out, opacity 0.2s ease-in-out";
const Element = {
  styleOverrides: {
    root: {
      background: "#FFF",
      height: "1px",
      width: "100px",
      margin: "2rem",
    },
  },
};

export default Element;

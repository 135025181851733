// import * as anchor from "@project-serum/anchor";

import { useMemo } from "react";
import { clusterApiUrl } from "@solana/web3.js";
// import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";

import { getPhantomWallet } from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
// import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";

const network = process.env.REACT_APP_SOLANA_NETWORK ?? "devnet";
// const rpcHost =
//   process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl("devnet");
// const connection = new anchor.web3.Connection(rpcHost);

export default function Component({ children }) {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(() => [getPhantomWallet()], []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets} autoConnect>
        {children}
      </WalletProvider>
    </ConnectionProvider>
  );
}
